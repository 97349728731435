import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import mask from '@alpinejs/mask'

import Forms from './forms'
import Flipper from './flipper'
import Slider from './slider'
import Maps from './maps'

import 'modules/tabs'
import 'modules/form'
import 'modules/search'
import 'modules/tooltip'
import 'modules/magicField'
import 'modules/accordion'
import 'modules/marquee'
import 'modules/scrollbars'

import { SmoothScroll, scrollTo } from './smoothscroll'

Alpine.plugin(mask)
Alpine.plugin(intersect)

const $container = $('.map')
const actions = {
  playVideo($elem, $target, container) {
    const $con = $target.find(container)
    if ($con.length) {
      const $video = $con.find('.video')

      if ($video.length) {
        // play video
        scrollTo(
          $video,
          { threshold: parseInt($(window).outerHeight() / 4) },
          () => {
            $video.parent().trigger('click')
            $video.data('video').playVideo()
          }
        )
      }
    }
  },
}

Slider()

Forms('form')

Flipper({
  callbacks: {
    onChange: ($wrap) => {
      const $map = $wrap.find('.map')
      if ($map.length && $map.hasClass('is-loaded')) {
        $map.data('map').resize(500)
      }
    },
    onFocus: ($wrap) => {
      const $video = $wrap.find('.video')
      if ($video.length) {
        $video.data('video').playVideo()
      }
    },
    onBlur: ($wrap) => {
      const $map = $wrap.find('.map')
      const $video = $wrap.find('.video')
      if ($map.length && $map.hasClass('is-loaded')) {
        $map.data('map').closeWindows()
      }
      if ($video.length) {
        $video.data('video').pauseVideo()
      }
    },
  },
})

SmoothScroll('.jumpto', ($elem, $target) => () => {
  const actionRaw = $elem.attr('data-action')
  if (actionRaw && actionRaw !== '') {
    let [action, args] = actionRaw.split(':')
    actions[action]($elem, $target, args)
  }
})

if ($container.length > 0) {
  Maps($container)
}

$(document).on('click', '.js-jump', (e) => {
  const $el = $(e.currentTarget)
  const id = $el.attr('href')

  if (id !== '#' && $(id).length > 0) {
    e.preventDefault()
    $('html, body').animate(
      {
        scrollTop: $(id).offset().top,
      },
      800
    )
  }
})

window.Alpine = Alpine
Alpine.start()
