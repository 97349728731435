import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('tooltip', function () {
    /** @type {import('@popperjs/core')} */
    let Popper = null
    let instance = null
    return {
      showTooltip: false,
      toggle(show = false) {
        this.showTooltip = show

        this.$nextTick(() => {
          const source = this.$refs.source || this.$el
          const tooltip = this.$refs.tooltip

          if (tooltip) {
            instance = Popper.createPopper(source, tooltip, {
              placement: 'auto',
              modifiers: [
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: ['top', 'bottom'],
                    allowedAutoPlacements: ['top', 'right', 'bottom'],
                  }
                },
                {
                  name: 'offset',
                  options: { offset: [0, 14] },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 10,
                  },
                },
              ],
            });
          } else {
            instance && instance.update()
          }
        })
      },
      listeners: {
        '@mouseenter'() { this.toggle(true) },
        '@mouseleave'() { this.toggle(false) },
        '@focus'() { this.toggle(true) },
        '@blur'() { this.toggle(false) },
      },
      async init() {
        /** @type {import('@popperjs/core')} */
        Popper = await import('@popperjs/core').catch(console.error)
      },
    }
  })
})()
